
import { computed, defineComponent, ref } from "vue";
import Entities from "../../components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import { Field } from "vee-validate";
import QuicksightService from "@/core/services/QuicksightService";
import Swal from "sweetalert2";

export default defineComponent({
  name: "List",
  components: {BaseForm, BaseModal, KtDatatable, QuickAction, Entities, Field},
  setup() {
    setCurrentPageBreadcrumbsWithParams("Quicksight Report",
      [
        {link: false, router: '', text: 'List'}
      ]
    )
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Dashboard ID",
        key: "dashboardId",
        sortable: true,
      },
      {
        name: "Height",
        key: "height",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    const page = computed(() => store.state.QuicksightModule.page);
    const state = ref({
      submitting: false,
      id: '',
      model: {name: '', code: '', dashboardId: '', height: 500, status: 'ACTIVE'}
    })
    return {
      state,
      tableHeader,
      page,
      ...LoadFilterObjects(Actions.LOAD_QUICKSIGHTS, {}, [])
    }
  },
  methods: {
    onUpdateStatus(item, status) {
      this.state.model = {
        name: item.name,
        code: item.code,
        dashboardId: item.dashboardId,
        height: item.height,
        status: status
      }
      this.state.id = item.id;
      if (this.state.id) {
        QuicksightService.update(this.state.id, this.state.model).then(() => {
          this.paginationLoad()
        }).finally(() => {
          this.state.submitting = false;
        })
      } else {
        QuicksightService.create(this.state.model).then(() => {
          this.paginationLoad()
        }).finally(() => {
          this.state.submitting = false;
        })
      }
    },
    onUpdate(item) {
      this.state.model = {
        name: item.name,
        code: item.code,
        dashboardId: item.dashboardId,
        height: item.height,
        status: item.status
      }
      this.state.id = item.id;
      const baseModalRef = this.$refs.refQuicksightForm as typeof BaseModal
      baseModalRef.showBaseModal()
    },
    onClose() {
      const baseModalRef = this.$refs.refQuicksightForm as typeof BaseModal
      baseModalRef.hideBaseModal()
    },
    addContent() {
      this.state.model = {name: '', code: '', dashboardId: '', height: 500, status: 'ACTIVE'}
      this.state.id = '';
      const baseModalRef = this.$refs.refQuicksightForm as typeof BaseModal
      baseModalRef.showBaseModal()
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await QuicksightService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    },
    onSave() {
      if (this.state.id) {
        QuicksightService.update(this.state.id, this.state.model).then(() => {
          this.paginationLoad()
          this.onClose()
        }).finally(() => {
          this.state.submitting = false;
        })
      } else {
        QuicksightService.create(this.state.model).then(() => {
          this.paginationLoad()
          this.onClose();
        }).finally(() => {
          this.state.submitting = false;
        })
      }
    }
  }
})
